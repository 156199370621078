import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import HeroDigitalMarketing from "../../components/ServiceSinglePage/Hero/DigitalMarketing/HeroDigitalMarketing"
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock"
import ServiceCTA from "../../components/ServiceLanding/CTA/ServiceCTA"
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs"
import HeroUIUX from "../../components/ServiceSinglePage/Hero/UIUX/HeroUIUX"
import FAQ from "../../components/FAQ/FAQ"
import faqs from "../../data/faq.json"
import { Helmet } from "react-helmet"

function UIUXPage({ location }) {
    const heroTextTitle = "UI/UX Design"
    const heroTextSmallText =
        "Elevate your brand with our bespoke UX/UI designs that blend stunning visuals with seamless functionality, driving conversions and captivating your audience for unforgettable digital experiences."
    const ctaTitle = "Elevate Your Digital Presence with Human-Centric Design"
    const heroData = [
        "At Saigon Digital, we know that a great UI/UX Design is the key to telling your brand's story across all platforms. We blend intuition with creativity to develop digital experiences that captivate and simplify. Our designs ensure that whether your audience is on a phone, tablet, or desktop, they enjoy a seamless and impactful interaction with your brand. By focusing on user-centric design, we forge meaningful connections between your brand and your audience, leaving lasting impressions and driving engagement."
    ]

    const strategyIcons = [
        {
            name: "checklist",
            title: "Case Analysis",
            bulletPoints: [
                "Functional requirment collection",
                "Business objectives assessment",
                "Tech stack choice",
                "Competitive research",
                "Target audience groups discovery"
            ]
        },
        {
            name: "search",
            title: "User Research",
            bulletPoints: [
                "Potential audience interviewing",
                "User expectation evalution",
                "Behavioral & attitudinal analysis",
                "User persona concepts creation",
                "Brainstorming, solution strategising"
            ]
        },
        {
            name: "dart",
            title: "Ideation",
            bulletPoints: [
                "Information architecture",
                "UX conceptualisation",
                "Storyboard creation",
                "Userflow diagramming",
                "Navigation structuring or site mapping"
            ]
        }
    ]

    const designIcons = [
        {
            name: "checklist",
            title: "Product design",
            bulletPoints: [
                "User-centered UX design",
                "Visual / UI Design",
                "UI motion design",
                "UI library, design system"
            ]
        },
        {
            name: "search",
            title: "Prototyping",
            bulletPoints: [
                "Wireframing",
                "Interactive UX prototyping",
                "Visual & UX mockups",
                "Sketching"
            ]
        },
        {
            name: "dart",
            title: "Accessibility",
            bulletPoints: [
                "UI/UX content",
                "UX copywriting",
                "Illustrations",
                "Graphics"
            ]
        }
    ]

    const consultIcons = [
        {
            name: "checklist",
            title: "Comprehesive UI/UX audit",
            bulletPoints: [
                "Accessibility checks",
                "Scalability analysis",
                "Localisation",
                "UI/UX testing",
                "Internal testing"
            ]
        },
        {
            name: "search",
            title: "Quantitative research",
            bulletPoints: [
                "Google analytics",
                "A/B testing",
                "Live user testing",
                "Solution beta launch"
            ]
        },
        {
            name: "dart",
            title: "UIUX strategy improvement",
            bulletPoints: [
                "User behaviour analysis",
                "Usability report",
                "Post-launch analytics report",
                "Intergrations & updates"
            ]
        }
    ]
    const context = {
        pageName: "Service | UI/UX Design",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <HeroUIUX
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                ctaTitle={ctaTitle}
                heroData={heroData}
            />
            <div className="[&_.service-icon]:pb-0 lg:[&_.service-icon]:pt-40 [&_.wave-bottom]:hidden">
                <ServiceIconBlock
                    title={"Strategy & Research"}
                    icons={strategyIcons}
                />
            </div>
            <div className="[&_.service-icon]:py-[120px] [&_.wave-bottom]:hidden [&_.wave-top]:hidden">
                <ServiceIconBlock title={"UI/UX Design"} icons={designIcons} />
            </div>
            <div className="[&_.service-icon]:pt-0 lg:[&_.service-icon]:pb-40 [&_.wave-top]:hidden">
                <ServiceIconBlock
                    title={"UI/UX Consulting"}
                    icons={consultIcons}
                />
            </div>
            <div className="mt-36">
                <ServiceCTA title={ctaTitle} />
            </div>
            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.uiuxFaqs} />
            </div>
            <LatestBlogs />
        </Layout>
    )
}

export default UIUXPage
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    mainEntity: [
                        {
                            "@type": "Question",
                            name: "Why should you choose Saigon Digital as your UI UX design agency?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "- User-Centric Design: We focus on human-centered design, ensuring a more intuitive and user-friendly experience for your customers.\n- Comprehensive Process: Our process includes all stages from strategy and research to prototyping and testing, guaranteeing a thorough approach to effective design.\n- Multi-Platform Expertise: We excel in creating responsive designs for phones, tablets, and desktops, ensuring a consistent experience across all platforms."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What is UX UI design?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "UX UI design encompasses both User Experience (UX) and User Interface (UI) design. UX design aims to enhance the overall experience users have with a product or service, while UI design focuses on the visual and interactive elements such as buttons, layouts, and color schemes. Together, these elements create a seamless and enjoyable experience that effectively meets user needs."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "Why is UX UI design important for your business?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Investing in high-quality UX UI design is crucial as it boosts user satisfaction, increases engagement, and can drive higher conversion rates. A well-designed user interface and experience ensure that users can navigate your website or product effortlessly, which ultimately supports your business’s success."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How do I know if I need UX UI design services?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "If you find your website or application challenging to navigate, with high bounce rates or unmet user needs, it might be time to consider UX UI design services. A professional UX UI design company can assess your current setup and recommend improvements to enhance usability and user satisfaction."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How long does a UX UI design project typically take?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "The duration of a UX UI design project can vary based on its complexity and scope. Typically, project timelines can vary from several weeks to multiple months. Contact Saigon Digital for a detailed project plan, including key milestones and deadlines, to keep you informed throughout the process."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "Can a UI UX design agency assist with both mobile and web design?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Absolutely. Our UI UX design services company caters to both mobile and web platforms. Whether you need a responsive website or a mobile application, we ensure that the designs deliver a consistent and effective user experience across all devices."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What are the costs associated with hiring a UI UX design company?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "The cost for UX UI design services depends on the project's scope and complexity. We provide a detailed quote and project estimate after discussing your specific needs. Our pricing is transparent and designed to offer significant value for your investment."
                            }
                        }
                    ]
                })}
            </script>
        </Helmet>
        <SEO
            title="UI UX Design | Saigon Digital"
            description="Explore Saigon Digital's UI/UX design services for seamless, user-centric digital experiences that drive engagement."
        />
    </>
)
